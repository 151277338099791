import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Immobilieninvestitionen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Immobilieninvestitionen – Gerade für Kleinanleger!" showCalc={false} />
            <Article>
                <p>
                    Sein Erspartes auf dem Konto liegen lassen und dabei deftige Zinsen von der Bank einstreichen? Wegen
                    der nun schon lang anhaltenden Niedrigzinspolitik der Zentralbanken gehört das leider der
                    Vergangenheit an. Immer mehr Menschen sind daher auf der Suche nach anderen Möglichkeiten, ihr Geld
                    sicher aber gleichzeitig mit guten Renditen anzulegen. Dabei erfreut sich der Markt für
                    Immobilieninvestitionen großer Beliebtheit – und das nicht nur bei Großinvestoren. Denn der Markt
                    für Immobilieninvestitionen hat sich in den letzten Jahren geändert, sodass nicht nur
                    Immobilienmogule mit viel Kapital im Rücken erfolgreich sein können. Auch Kleinanleger können hier
                    mit der richtigen Strategie mehr aus ihrem Geld machen, als es bloß auf der Bank zu parken. Die
                    Renditen – so Langzeitstudien– sind auf dem ganzen Finanzmarkt für Kleinanleger mit am höchsten. Wir
                    wollen wissen: Welche Möglichkeiten haben Anleger und welche sind für Kleinanleger am attraktivsten?
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Direkte Investitionen</h2>
                <p>
                    Bei Investitionen in Immobilien unterscheidet man grundsätzlich zwischen direkten und indirekten.
                    Bei der direkten Investition bist du es selbst, der die Immobilie erwirbt. Folgende Möglichkeiten
                    der direkten Immobilieninvestition haben Anleger:
                </p>
                <ul>
                    <li>
                        Der Erwerb eines <strong>Eigenheims</strong>, also einer Eigentumswohnung oder eines Hauses, um
                        die Immobilie selbst zu bewohnen ist die klassische und wohl häufigste Immobilieninvestition.
                        Was für und gegen das Eigenheim oder die Mietwohnung spricht, das kannst du gerne{" "}
                        <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                            hier
                        </Link>{" "}
                        nachlesen. Und falls du dir direkt einen ersten Überblick über Kreditkosten und mehr machen
                        willst, kannst du das jetzt sofort mit unseren{" "}
                        <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                            Kreditrechner
                        </Link>{" "}
                        machen.
                    </li>
                    <li>
                        Genauso ist der Erwerb einer <strong>Vermietungsimmobilie</strong> oder Vorsorgewohnung eine für
                        viele attraktive– gerade als Altersvorsorge - aber langfristige Investition. Hier erwirbt man
                        die Immobilie und vermietet sie im Anschluss selbst. Was bei der sogenannten „buy-and-hold“
                        Variante zu beachten ist, das haben wir schon{" "}
                        <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                            hier
                        </Link>{" "}
                        für dich zusammengetragen.
                    </li>
                    <li>
                        <strong>Bei der „fix-and-flip“</strong> Variante wird ein sanierungsbedürftiges Objekt gekauft
                        und auf Vordermann gebracht um dann zum höchstmöglichen Preis weiterverkauft. Für Kleinanleger
                        empfiehlt sich diese Variante eher nicht, da viel und flüssiges Eigenkapital, sowie Connections
                        zu Maklern und Handwerkern auf jeden Fall von Vorteil sind.
                    </li>
                </ul>
                <p>
                    Was direkte Immobilieninvestitionen ausmacht, ist, dass sie einen Grundstock an Eigenkapital
                    voraussetzen, zumeist mindestens 50.000 Euro, um überhaupt eine Immobilie erwerben zu können. Dazu
                    stellen sie eine langfristige Kapitalbindung dar, bedürfen in aller Regel einer Finanzierung und
                    viel Aufmerksamkeit und Mühen des Investors. Als Kleinanleger, der ja gerade nicht so viel Geld in
                    eine Investition stecken möchte ist diese Form der Immobilieninvestition als reine Kapitalanlage
                    daher nicht wirklich sinnvoll.
                </p>
                <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                    Kreditvergleich anfordern
                </a>
                <hr />

                <h2>Indirekte Investitionen</h2>
                <p>
                    Warum sind Immobilieninvestitionen bei Kleinanlegern also so beliebt? Die Antwort: indirekte
                    Immobilienanlagen. Dabei beteiligt sich der Investor durch einen unabhängigen Rechtsträger (zum
                    Beispiel durch einen Fonds) an Immobilienprojekten. Man ist selbst nicht Eigentümer, wodurch alle
                    Eigentümerpflichten und -mühen entfallen, bezieht aber anteilig die Renditen. Dies birgt für
                    Kleinanleger einige Vorteile. Überhaupt ist eine Investition auch mit geringem Kapital möglich und
                    ein Split über verschiedene Anlagenerlaubt Diversifikation. Die langfristige Kapitalbindung entfällt
                    und eine Finanzierung und damit einhergehende Verschuldung – wie sie bei direkten
                    Immobilieninvestitionen üblich ist - kann umgangen werden. Was sie aber vor allem auszeichnet ist,
                    dass sie für dich als Investor deutlich entspannter ist. Denn nicht nur die Eigentümerpflichten
                    entfallen. Die oft schwierige Auswahl des richtigen Objekts, die Instandhaltung oder -setzung durch
                    verschiedene Handwerkerbüros, bürokratische Pflichten wie die Grundbucheintragung und Notartermine,
                    die wieder Kaufnebenkosten mit sich ziehen – wer sich für eine indirekte Immobilieninvestition
                    entscheidet, spart sich all diese Kosten und Mühen.
                </p>
                <p>
                    Wenn du also in Immobilien investieren möchtest, ohne gleich eine Immobilie zu erwerben, hast du zum
                    Beispiel folgende Möglichkeiten:
                </p>
                <ul>
                    <li>
                        <strong>Immobilienfonds</strong> sind Gesellschaften, in denen mehrere Anleger ihr Kapital
                        bündeln und dieses zu mindestens 51% in Immobilien investieren wird. Es wird unterschieden
                        zwischen offenen und geschlossenen Immobilienfonds. Offene Immobilienfonds investieren in der
                        Regel und im Gegensatz zu geschlossenen in mehrere Projekte gleichzeitig, sind durch die
                        Diversifikation also risikoärmer. Außerdem werden sie gehandelt und haben üblicherweise ein
                        deutliches geringeres Mindestinvestitionsvolumen, was sie für Kleinanleger attraktiver macht als
                        Geschlossene. Natürlich immer möglich ist es, auch einzelne Aktien von börsennotierten
                        Unternehmen aus der Immobilienbranche zu erwerben.
                    </li>
                    <li>
                        <strong>ETFs</strong> oder börsengehandelte Fonds bilden Indizes unterschiedlichster Branchen
                        ab. Dabei erfreuen sich Immobilien-ETFs immer größerer Beliebtheit. Im Unterschied zum Fonds
                        gibt es hier keinen aktiven Fondsmanager, der Investitionsprojekte durchführt. Vielmehr werden
                        Aktien von Unternehmen gebündelt, die im Immobiliensektor tätig sind, und dann über die Börse in
                        diese investiert.
                    </li>
                    <li>
                        <strong>Crowdinvesting</strong> ist eine recht neue Möglichkeit, in Immobilien zu investieren,
                        die durch das Internet ermöglicht wurde. Über Platformen finden sogenannte Mikroinvestoren
                        zusammen, wodurch neben Startups und anderem, auch Bauprojekte finanziert werden.Hier locken -
                        bei meist hohen Renditen - kurze Laufzeiten und niedrige Mindestinvestionsvolumina viele
                        Kleinanleger an. Dafür ist auch das Risiko des Ausfalls deutlich höher als beispielsweise bei
                        etablierten Immobilienfonds. Außerdem ist dieser Markt durch sein junges Alter noch relativ
                        unreguliert, was einen weiteren Risikofaktor darstellt.
                    </li>
                </ul>
                <hr />

                <h2>Fazit</h2>
                <p>
                    Alles in allem lässt sich zusammenfassen: Auch Kleinanleger mit geringem Eigenkapital können heute
                    auf dem Markt für Immobilieninvestitionen ohne große Verschuldung und übermäßigen Aufwand via
                    indirekten Immobilienanlagen gute Renditen erwirtschaften. Dies stellt eine sinnvolle Alternative
                    zur Einlage auf dem Sparkonto dar. Denn auf absehbare Zeit werden Geschäftsbanken keine Zinsen an
                    ihre Einleger zahlen können, die auch nur so hoch sind wie durchschnittliche Renditen auf dem
                    Finanzmarkt für Immobilieninvestitionen.
                </p>
            </Article>
            <MortgageCalculator noTopMargin />

            <BreadcrumbList page={"immobilieninvestitionen"}></BreadcrumbList>
            <ArticleStructuredData
                page={"immobilieninvestitionen"}
                heading={"Immobilieninvestitionen – Gerade für Kleinanleger!"}
            />
        </Layout>
    );
};

export default Immobilieninvestitionen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "mortgageCalc", "page.artikel.immobilieninvestitionen"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
